/* Roboto Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.task-container, .assigned-people-container {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  padding: 20px;
  max-width: 800px;
  border-radius: 8px;
}

h2 {
  color: #333;
}

.task-list, .assigned-people-list {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
}

.task-list th, .task-list td, .assigned-people-list th, .assigned-people-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #dc3545;
}

/* Modal */
.modal {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* Buttons */
button {
  font-family: 'Roboto', sans-serif;
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.add-task-button, .add-person-button {
  background-color: #28a745;
  color: white;
}

.export-button {
  background-color: #007bff;
  color: white;
}

.ok-button {
  background-color: #007bff;
  color: white;
}

/* Calendar */
.date-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Task Modal Styles */
.task-modal {
  max-width: 600px;
}

/* Assigned People Table */
.assigned-people-list th, .assigned-people-list td {
  padding: 10px;
  text-align: left;
}

.add-person-button {
  margin-left: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .task-container, .assigned-people-container {
    width: 90%;
    padding: 10px;
  }

  button {
    font-size: 12px;
  }

  .modal {
    width: 90%;
    padding: 10px;
  }
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}