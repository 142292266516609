@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color: white;
}
table {
  width: 100%;
  border-collapse: collapse;
}

th {
  background-color: #f8fafc;
}

td, th {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #e2e8f0;
}